import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { LoadingContext } from 'src/context';
import styled, { css } from 'styled-components';
import MediaQuery from 'react-responsive';
import { Box, Image, Text } from 'rebass';
import { Grid, Col } from 'src/components/utilities/Grids';
import { Container } from 'src/components/utilities/Containers';
import AuthorFolders from './AuthorFolders';
import AuthorCollections from './AuthorCollections';
import OfficialCollections from './OfficialCollections';
import Classrooms from './Classrooms';
import { breakpoints } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';
import AccountSvg from 'assets/svg/account.svg';
import { formatSchoolDegrees } from 'src/utils/degrees';

const avatarStyle = css`
  width: 4rem;
  height: 4rem;
`;

export const AccountIcon = styled(AccountSvg)`
  ${svgStyle};
  ${avatarStyle};
`;

class TeacherDashboard extends PureComponent {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    collections: PropTypes.array.isRequired,
    folders: PropTypes.array.isRequired,
    classrooms: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    officialCollections: PropTypes.object,
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  render() {
    const { t, collections, folders, user, officialCollections, classrooms } = this.props;
    const userSchoolDegrees = user.schools.length > 0 ? formatSchoolDegrees(user.schools) : null;

    return (
      <Container css="padding-bottom: 3rem;">
        <MediaQuery maxWidth={breakpoints.mdPlus}>
          <Grid pt="1.8rem" alignItems="center" justifyContent="center">
            <Col flex="0 0 auto">
              {user.avatar ? (
                <Box css={avatarStyle}>
                  {user.avatar && (
                    <Image
                      src={`${process.env.ASSETS_BASE_URL}${process.env.AVATARS_UPLOAD_DIR}/${user.avatar.image}`}
                    />
                  )}
                </Box>
              ) : (
                <AccountIcon />
              )}
            </Col>
            <Col flex="0 0 auto">
              <Text fontFamily="heading" fontSize={['1.6rem', '2rem']} fontWeight="medium">
                {t('welcome')} {user.firstname} {user.lastname}
              </Text>
            </Col>
          </Grid>
        </MediaQuery>
        <Classrooms classrooms={classrooms} />
        <AuthorCollections collections={collections} user={user} />
        <AuthorFolders folders={folders} user={user} />
        {officialCollections && <OfficialCollections collections={officialCollections} userSchoolDegrees={userSchoolDegrees} />}
      </Container>
    );
  }
}

export default withNamespaces()(TeacherDashboard);
