"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOIN_CLASSROOM = exports.GET_CLASSROOMS = exports.GET_CLASSROOM = exports.REMOVE_CLASSROOM = exports.RESTRICTED_CLASSROOMS = exports.ADD_CLASSROOM = void 0;
var gql_1 = require("src/gql");
exports.ADD_CLASSROOM = (0, gql_1.graphql)(/* GraphQL */ "\n  mutation addClassroom($input: AddClassroomInput) {\n    addClassroom(input: $input) {\n      id\n      name\n    }\n  }\n");
exports.RESTRICTED_CLASSROOMS = (0, gql_1.graphql)(/* GraphQL */ "\n  query restrictedClassroom($id: ID!) {\n    classroom(id: $id) {\n      id\n      name\n    }\n  }\n");
exports.REMOVE_CLASSROOM = (0, gql_1.graphql)(/* GraphQL */ "\n  mutation removeClassroom($classroom: ID!) {\n    removeClassroom(classroom: $classroom)\n  }\n");
exports.GET_CLASSROOM = (0, gql_1.graphql)(/* GraphQL */ "\n  query classroom($id: ID!) {\n    classroom(id: $id) {\n      id\n      name\n      students {\n        id\n        firstname\n        username\n        avatar {\n          ...AvatarParts\n        }\n        userLinkCards {\n          card {\n            id\n          }\n          status\n        }\n      }\n    }\n  }\n");
exports.GET_CLASSROOMS = (0, gql_1.graphql)(/* GraphQL */ "\n  query classrooms(\n    $page: Int = 1\n    $limit: Int\n    $filterBy: [FilterBy]\n    $orderBy: [OrderBy]\n  ) {\n    classrooms(limit: $limit, page: $page, filterBy: $filterBy, orderBy: $orderBy) {\n      count\n      results {\n        id\n        name\n        teacher {\n          firstname\n          lastname\n        }\n        students {\n          id\n          firstname\n          username\n          avatar {\n            ...AvatarParts\n          }\n        }\n      }\n    }\n  }\n");
exports.JOIN_CLASSROOM = (0, gql_1.graphql)(/* GraphQL */ "\n  mutation joinClassroom($classroom: ID!) {\n    joinClassroom(classroom: $classroom)\n  }\n");
