"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var update_svg_1 = require("assets/svg/update.svg");
var React = require("react");
var Link_1 = require("react-router-dom/Link");
var react_tooltip_1 = require("react-tooltip");
var react_truncate_1 = require("react-truncate");
var rebass_1 = require("rebass");
var Heading_1 = require("src/components/typography/Heading");
var helpers_1 = require("src/styles/helpers");
var theme_1 = require("src/styles/theme");
var styled_components_1 = require("styled-components");
var Icons_1 = require("../Onboarding/Icons");
var util_1 = require("src/utils/util");
var Card = styled_components_1.default.section(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow: hidden;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  border-radius: 0.4rem;\n  box-shadow: 0 2px 4px 1px rgba(148, 140, 140, 0.2);\n  ", "\n  &::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    right: 0;\n    left: 0;\n    display: block;\n    height: 0.6rem;\n    background-color: ", ";\n  }\n  ", ";\n"], ["\n  overflow: hidden;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  border-radius: 0.4rem;\n  box-shadow: 0 2px 4px 1px rgba(148, 140, 140, 0.2);\n  ", "\n  &::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    right: 0;\n    left: 0;\n    display: block;\n    height: 0.6rem;\n    background-color: ", ";\n  }\n  ", ";\n"])), function (props) { return props.dashboardCard ? "height: 224px;" : ""; }, theme_1.theme.colors.primary, function (props) { return props.css; });
var Container = (0, styled_components_1.default)(Link_1.default)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1 1 auto;\n  padding: 1.2rem;\n  background-color: ", ";\n  color: ", ";\n  text-decoration: none;\n  cursor: pointer;\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1 1 auto;\n  padding: 1.2rem;\n  background-color: ", ";\n  color: ", ";\n  text-decoration: none;\n  cursor: pointer;\n"])), theme_1.theme.colors.white, theme_1.theme.colors.text);
var StyledBox = (0, styled_components_1.default)(rebass_1.Box)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: ", ";\n  text-decoration: none;\n  cursor: pointer;\n  ", ";\n\n  &:hover {\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: ", ";\n  text-decoration: none;\n  cursor: pointer;\n  ", ";\n\n  &:hover {\n    color: ", ";\n  }\n"])), theme_1.theme.colors.text, function (props) { return props.css; }, function (props) { return props.color || theme_1.theme.colors.secondary; });
var StyledLink = (0, styled_components_1.default)(Link_1.default)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: ", ";\n  text-decoration: none;\n  cursor: pointer;\n  ", ";\n\n  &:hover {\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: ", ";\n  text-decoration: none;\n  cursor: pointer;\n  ", ";\n\n  &:hover {\n    color: ", ";\n  }\n"])), theme_1.theme.colors.text, function (props) { return props.css; }, function (props) { return props.color || theme_1.theme.colors.secondary; });
var UpdateIcon = (0, styled_components_1.default)(update_svg_1.default)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  width: 2.4rem;\n  height: 2.2rem;\n  margin-right: 1em;\n  fill: ", ";\n"], ["\n  ", ";\n  width: 2.4rem;\n  height: 2.2rem;\n  margin-right: 1em;\n  fill: ", ";\n"])), helpers_1.svgStyle, theme_1.theme.colors.secondary);
var CopyIconStyled = (0, styled_components_1.default)(Icons_1.CopyIcon)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", ";\n  width: 2.4rem;\n  height: 2.2rem;\n  margin-right: 1rem;\n  stroke-width: 2px;\n  stroke: ", ";\n  fill: none;\n"], ["\n  ", ";\n  width: 2.4rem;\n  height: 2.2rem;\n  margin-right: 1rem;\n  stroke-width: 2px;\n  stroke: ", ";\n  fill: none;\n"])), helpers_1.svgStyle, theme_1.theme.colors.primary);
var InfoBox = (0, styled_components_1.default)(rebass_1.Box)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding-top: .5rem;\n  padding-bottom: .5rem;\n  font-size: 1.2rem;\n  color: #636363;\n  background-color: #FAFAFA;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding-top: .5rem;\n  padding-bottom: .5rem;\n  font-size: 1.2rem;\n  color: #636363;\n  background-color: #FAFAFA;\n"])));
var Footer = (0, styled_components_1.default)(rebass_1.Box)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  padding-top: 1.2rem;\n  padding-bottom: 1.2rem;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  padding-top: 1.2rem;\n  padding-bottom: 1.2rem;\n"])));
var ClassroomCard = function (_a) {
    var classroom = _a.classroom, t = _a.t, dashboardCard = _a.dashboardCard;
    var _b = React.useState(false), tooltipOpen = _b[0], setTooltipOpen = _b[1];
    var handleCopy = function (e) {
        (0, util_1.copyToClipboard)("".concat(window.location.origin, "/classes/").concat(classroom.id, "/rejoindre"));
        e.preventDefault();
        setTooltipOpen(true);
        setTimeout(function () {
            setTooltipOpen(false);
        }, 1000);
    };
    return (React.createElement(Card, { dashboardCard: dashboardCard },
        React.createElement(react_tooltip_1.Tooltip, { id: 'copy-tooltip', place: 'top', isOpen: tooltipOpen, style: { backgroundColor: "#27ae60", color: "#ecf0f1", borderRadius: "6px" } },
            React.createElement("span", null, "Copié !")),
        React.createElement(Container, { to: "/classes/".concat(classroom.id, "/editer") },
            React.createElement(Heading_1.default, { as: "h5", pb: "1.8rem", lineHeight: "1.4", css: "display: flex; flex: 1 1 auto; align-items: center;", color: undefined, fontSize: undefined, fontWeight: undefined },
                React.createElement(react_truncate_1.default, { lines: 2, ellipsis: "..." }, classroom.name)),
            (classroom === null || classroom === void 0 ? void 0 : classroom.teacher) && (React.createElement(rebass_1.Text, { as: "span", pt: ".6rem", fontSize: "1.2rem", fontWeight: "semibold" }, "Par ".concat(classroom.teacher.firstname, " ").concat(classroom.teacher.lastname)))),
        React.createElement(InfoBox, null, classroom.students.length > 0 ? t('classroomStudentsCount', { count: classroom.students.length }) : 'Aucun élève'),
        React.createElement(Footer, null,
            React.createElement(StyledBox, { onClick: handleCopy, color: theme_1.theme.colors.primary, "data-tooltip-id": "copy-tooltip" },
                React.createElement(CopyIconStyled, null),
                React.createElement(rebass_1.Text, { as: "span", fontFamily: "heading", fontSize: "1.4rem", fontWeight: "medium", title: "Copier le lien de la classe" }, "Copier le lien")),
            React.createElement(StyledLink, { to: "/classes/".concat(classroom.id, "/editer") },
                React.createElement(UpdateIcon, null),
                React.createElement(rebass_1.Text, { as: "span", fontFamily: "heading", fontSize: "1.4rem", fontWeight: "medium" }, "Modifier")))));
};
exports.default = ClassroomCard;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
