import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Content } from 'src/components/utilities/Containers';
import { Col, Grid } from 'src/components/utilities/Grids';
import ClassroomCard from "src/pages/AuthorClassrooms/ClassroomCard";
import { svgStyle } from 'src/styles/helpers';
import { breakpoints } from 'src/styles/theme';
import ClassroomSvg from 'assets/svg/classroom.svg';
import BuildButton from 'src/components/elements/BuildButton';
import Title from 'src/components/typography/Title';
import styled from 'styled-components';
import { Box, Text } from 'rebass';
import { LinkButton } from 'src/components/elements/Button';

const ClassroomIcon = styled(ClassroomSvg)`
  ${svgStyle};
  height: 2rem;

  @media (min-width: ${breakpoints.md}) {
    min-height: 2.6rem;
  }
`;

class Classrooms extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    classrooms: PropTypes.array,
  };

  render() {
    const { t, classrooms } = this.props;

    return (
      <Content mt={4}>
        <Title sm icon={<ClassroomIcon />}>
          {t('myClassrooms')}
        </Title>
          <Grid mt={2}>
            <Col width={[1 / 1, 1 / 1, 1 / 2, 1 / 3]} pb={2}>
              <BuildButton route={'/classes/creer'} label="Créer une classe" />
            </Col>
            {classrooms.results.map((classroom, key) =>
              <Col key={key} width={[1 / 1, 1 / 1, 1 / 2, 1 / 3]} pb={2}>
                <ClassroomCard dashboardCard={true} key={classroom.id} classroom={classroom} t={t} />
              </Col>)}
          </Grid>
          {classrooms.count > 2 && (
            <Text pt={1} textAlign="center">
              <Box pb={2}>
                <LinkButton
                  to={`/mes-classes`}
                >
                  {t('seeAllMyClasses')}
                </LinkButton>
              </Box>
            </Text>
          )}
        </Content>
    );
  }
}

export default withNamespaces()(Classrooms);
